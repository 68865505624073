export const fetchOutboundCalls = () => ({
    type: 'FETCH_OUTBOUND_CALLS',
  });
  
  export const fetchOutboundCallsSuccess = (calls) => ({
    type: 'FETCH_OUTBOUND_CALLS_SUCCESS',
    payload: calls,
  });
  
  export const fetchOutboundCallsFailure = (error) => ({
    type: 'FETCH_OUTBOUND_CALLS_FAILURE',
    error
  });
  
  export const setSearchTerm = (term) => ({
    type: 'SET_SEARCH_TERM',
    payload: term,
  });
  
  // actions.js or wherever you define actions
  export const fetchSignedUrl = (call_id) => ({
    type: 'FETCH_SIGNED_URL_REQUEST',
    payload: { call_id }
  });
  
  export const fetchSignedUrlSuccess = (url) => ({
    type: 'FETCH_SIGNED_URL_SUCCESS',
    payload: { url }
  });
  
  export const fetchSignedUrlFailure = (error) => ({
    type: 'FETCH_SIGNED_URL_FAILURE',
    payload: { error }
  });