import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: (theme.vars || theme).palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: 'center',
  },
}));

export default function NavbarBreadcrumbs({ activeSection }) {
  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextRoundedIcon fontSize="small" />}
    >
      <Typography variant="body1">Dashboard</Typography>
      <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 600 }}>
        {activeSection === 'admin' && 'Admin'}
        {activeSection === 'monthlyMetrics' && 'Monthly Metrics'}
        {activeSection === 'inboundCalls' && 'Inbound Calls'}
        {activeSection === 'outboundCalls' && 'Outbound Calls'}
        {activeSection === 'liveCalls' && 'Live Calls'}
        {activeSection === 'vettedvoiceAssistant' && 'VettedVoice Assistant'}
        {!['admin', 'monthlyMetrics', 'inboundCalls', 'outboundCalls', 'liveCalls', 'vettedvoiceAssistant'].includes(activeSection) && 'Unknown'}
      </Typography>
    </StyledBreadcrumbs>
  );
}