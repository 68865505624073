import React from 'react';
import '../css/Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="copyright-container">
      <p>Copyright &copy; {currentYear} VettedVoice. All rights reserved.</p>
    </div>
  );
}

export default Footer;
