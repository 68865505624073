// // redux/store.js before adding redux-perist
// import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './reducers'; // Combined reducer
// import createSagaMiddleware from 'redux-saga';
// import rootSaga from './sagas'; // Root saga


// const sagaMiddleware = createSagaMiddleware();

// const getInitialState = () => {
//     try {
//       const token = localStorage.getItem('token');
//       const user = JSON.parse(localStorage.getItem('user'));
//       if (token && user) {
//         return {
//           auth: {
//             isLoggedIn: true,
//             user,
//             token,
//             error: null,
//             loading: false,
//           },
//         };
//       }
//     } catch (error) {
//       console.error('Failed to load state from localStorage:', error);
//     }
//     return undefined; // Fallback to undefined if no valid state found
//   };

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
// });

// sagaMiddleware.run(rootSaga);

// export default store;
// redux/store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Uses localStorage
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import authReducer from './reducers/authReducer'; 
import uiReducer from './slices/uiSlice';
import inboundCallsReducer from './reducers/inboundCallsReducer';
import outboundCallsReducer from './reducers/outboundCallsReducer';

// Configure persistence
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Specify which reducers to persist
};

const rootReducer = combineReducers({
  auth: authReducer,
  inboundCalls: inboundCallsReducer,
  outboundCalls: outboundCallsReducer,
    // ui: uiReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false, // Ignore serialization checks for redux-persist
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store); // Create the persistor
export default store;
