// // import React, { useContext } from 'react';
// // import { Navigate } from 'react-router-dom';
// // import { AuthContext } from '../contexts/AuthContext'; // Assuming you are using AuthContext

// // function ProtectedRoute({ children, requireAdmin = false }) {
// //   const { auth } = useContext(AuthContext); // Retrieve auth from context
// //   const token = localStorage.getItem('token'); // Retrieve the token from localStorage

// //   if (!token) {
// //     // If no token is found, redirect to login page
// //     return <Navigate to="/login" replace />;
// //   }

// //   // If the route requires admin access, check if the user has the Admin role
// //   if (requireAdmin && auth.user?.userRole !== 'Admin') {
// //     return <Navigate to="/login" replace />; // Redirect if the user is not an admin
// //   }

// //   // If token exists and user has proper access, render the child component
// //   return children;
// // }

// // export default ProtectedRoute;

// // src/components/ProtectedRoute.js

// import React from 'react';
// import { useSelector } from 'react-redux';
// import { Navigate } from 'react-router-dom';
// import { jwtDecode } from 'jwt-decode';

// function ProtectedRoute({ children, requireAdmin = false }) {
//   // Access authentication state from Redux
//   const { isLoggedIn, user, loading } = useSelector((state) => state.auth);

//   const isTokenValid = () => {
//     const token = localStorage.getItem('token');
//     if (!token) return false;
    
//     try {
//       const decoded = jwtDecode(token); // Changed from jwt_decode to jwtDecode
//       return decoded.exp > Date.now() / 1000;
//     } catch (error) {
//       console.error('Error decoding token:', error);
//       return false;
//     }
//   };

//   if (loading) {
//     return <p>Loading...</p>; // Replace with a spinner or loader if preferred
//   }

//   // If not logged in, redirect to the login page
//   if (!isLoggedIn || !isTokenValid()) {
//     return <Navigate to="/login" replace />;
//   }

//   // If admin access is required, verify the user's role
//   if (requireAdmin && user?.userRole !== 'Admin') {
//     // Optionally, you can redirect to a "Not Authorized" page or another appropriate route
//     return <Navigate to="/dashboard" replace />;
//   }

//   // If all checks pass, render the child components
//   return children;
// }

// export default ProtectedRoute;

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children, requireAdmin = false }) {
  // Access authentication state from Redux
  const { isLoggedIn, user, loading } = useSelector((state) => state.auth);

  // If we're still loading initial auth state, show a loading indicator
  if (loading) {
    return <p>Loading...</p>;
  }

  // If not logged in, redirect to the login page
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // If admin access is required, verify the user's role
  if (requireAdmin && user?.userRole !== 'Admin') {
    // Optionally, you can redirect to a "Not Authorized" page or another appropriate route
    return <Navigate to="/dashboard" replace />;
  }

  // If all checks pass, render the child components
  return children;
}

export default ProtectedRoute;
