export const fetchInboundCalls = () => ({
  type: 'FETCH_INBOUND_CALLS',
});

export const fetchInboundCallsSuccess = (calls) => ({
  type: 'FETCH_INBOUND_CALLS_SUCCESS',
  payload: calls,
});

export const fetchInboundCallsFailure = (error) => ({
  type: 'FETCH_INBOUND_CALLS_FAILURE',
  error
});

export const setSearchTerm = (term) => ({
  type: 'SET_SEARCH_TERM',
  payload: term,
});

// actions.js or wherever you define actions
export const fetchSignedUrl = (call_id) => ({
  type: 'FETCH_SIGNED_URL_REQUEST',
  payload: { call_id }
});

export const fetchSignedUrlSuccess = (url) => ({
  type: 'FETCH_SIGNED_URL_SUCCESS',
  payload: { url }
});

export const fetchSignedUrlFailure = (error) => ({
  type: 'FETCH_SIGNED_URL_FAILURE',
  payload: { error }
});

export const fetchPdfSignedUrlRequest = (call_id) => ({
  type: 'FETCH_PDF_SIGNED_URL_REQUEST',
  payload: { call_id },
});

export const fetchPdfSignedUrlSuccess = (pdfUrl) => ({
  type: 'FETCH_PDF_SIGNED_URL_SUCCESS',
  payload: { pdfUrl },
});

export const fetchPdfSignedUrlFailure = (error) => ({
  type: 'FETCH_PDF_SIGNED_URL_FAILURE',
  payload: { error },
});