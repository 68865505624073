import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSearchTerm } from '../../src/redux/actions/inboundCallsActions';

const SearchBar = () => {
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    dispatch(setSearchTerm(value));
  };

  return (
    <input 
      type="text" 
      value={searchValue} 
      onChange={handleChange} 
      placeholder="Search calls..."
    />
  );
};

export default SearchBar;