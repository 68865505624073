const initialState = {
  calls: [],
  loading: false,
  error: null,
  searchTerm: '',
};

const inboundCallsReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'FETCH_INBOUND_CALLS':
      return { ...state, loading: true, error: null }; // Clear error on new fetch
    case 'FETCH_INBOUND_CALLS_SUCCESS':
      return { ...state, loading: false, calls: action.payload, error: null };
    case 'FETCH_INBOUND_CALLS_FAILURE':
      return { ...state, loading: false, error: action.error };
    case 'SET_SEARCH_TERM':
      return { ...state, searchTerm: action.payload };
      case 'FETCH_SIGNED_URL_REQUEST':
      return { ...state, loading: true, error: null, audioUrl: null };
    case 'FETCH_SIGNED_URL_SUCCESS':
      return { ...state, loading: false, audioUrl: action.payload.url, error: null };
    case 'FETCH_SIGNED_URL_FAILURE':
      return { ...state, loading: false, error: action.payload.error, audioUrl: null };
      case 'FETCH_PDF_SIGNED_URL_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
        pdfUrl: null
      };
    case 'FETCH_PDF_SIGNED_URL_SUCCESS':
      return {
        ...state,
        loading: false,
        pdfUrl: action.payload.pdfUrl,
        error: null
      };
    case 'FETCH_PDF_SIGNED_URL_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        pdfUrl: null
      };
    default:
      return state;
  }
};


export default inboundCallsReducer;