
//loginSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
//import axios from 'axios';
import axiosInstance from '../../api/axios';
import { loginSuccess, loginFailed, logoutSuccess } from '../actions/loginActions'; // Login actions

function* login(action) {
  try {
    const response = yield call(axiosInstance.post, '/auth/login', action.payload);
    const user = response.data;

    // Store the token if needed
    localStorage.setItem('token', user.token);

    // Dispatch success action
    yield put(loginSuccess(user));
  } catch (error) {
    yield put(loginFailed(error.message));
  }
}

// Watcher saga
export default function* loginSaga() {
  yield takeLatest('LOGIN_REQUESTED', login);
}
