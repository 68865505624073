const initialState = {
    calls: [],
    loading: false,
    error: null,
    searchTerm: '',
  };
  
  const outboundCallsReducer = (state = initialState, action) => {
    switch(action.type) {
      case 'FETCH_OUTBOUND_CALLS':
        return { ...state, loading: true, error: null }; // Clear error on new fetch
      case 'FETCH_OUTBOUND_CALLS_SUCCESS':
        return { ...state, loading: false, calls: action.payload, error: null };
      case 'FETCH_OUTBOUND_CALLS_FAILURE':
        return { ...state, loading: false, error: action.error };
      case 'SET_SEARCH_TERM':
        return { ...state, searchTerm: action.payload };
        case 'FETCH_SIGNED_URL_REQUEST':
        return { ...state, loading: true, error: null, audioUrl: null };
      case 'FETCH_SIGNED_URL_SUCCESS':
        return { ...state, loading: false, audioUrl: action.payload.url, error: null };
      case 'FETCH_SIGNED_URL_FAILURE':
        return { ...state, loading: false, error: action.payload.error, audioUrl: null };
      default:
        return state;
    }
  };
  
  
  export default outboundCallsReducer;