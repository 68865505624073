import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import About from './pages/About/About';
import Privacy from './pages/Privacy/Privacy';
import FAQ from './pages/FAQ/FAQ';
import Terms from './pages/Terms/Terms';
import Manufacturing from './pages/Manufacturing/Manufacturing';
import Landscaping from './pages/Landscaping/Landscaping';
import Construction from './pages/Construction/Construction';
import Restaurant from './pages/Restaurant/Restaurant';
import Hospitality from './pages/Hospitality/Hospitality';
import Health from './pages/Health/Health';
import OilAndGas from './pages/OG/Oil';
import Warehouse from './pages/Warehouse/Warehouse';
import Logistics from './pages/Logistics/Logistics';  
import CaseStudy from './pages/CaseStudy/CaseStudy';
import Compliance from './pages/Compliance/Compliance';
import Checklist from './pages/Checklist/Checklist';
import Workflow from './pages/Workflow/Workflow';
import InterviewSC from './pages/InterviewSC/InterviewSC';
import Product from './pages/Product/Product';
import Contact from './pages/Contact/Contact';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import VideoContainer from './components/VideoContainer';
import ContentContainer from './components/ContentContainer';
import FeaturesContainer from './components/FeaturesContainer';
import LanguageContainer from './components/LanguageContainer';
import AnyIndustry from './components/AnyIndustry';
import Expertise from './components/Exptertise';
import AvailabilityContainer from './components/AvailabilityContainer';
// import TestimonialsContainer from './components/TestimonialsContainer';
// import ContactContainer from './components/ContactContainer';
import LinksContainer from './components/LinksContainer';
import AgentsOverview from '../src/pages/AgentsOverview/AgentsOverview';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoutes';
import AdminDashboard from './components/AdminDashboard';
import BlogList from './components/BlogList';
import ErrorPage from './components/ErrorPage';
import LoadingOverlay from './components/LoadingOverlay'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function App() {
  const location = useLocation();

  const loading = useSelector((state) => state.auth.loading);

  // Specify the paths where the Navbar should be hidden
  const hideNavbarRoutes = ['/login', '/register', '/dashboard', '/admin-dashboard']; 
  const hideFooterRoutes = ['/login', '/register', '/blog', '/privacy', '/terms-of-service', '/admin-dashboard', '/dashboard']; 

  return (
    
      <div className="App">
        {/* Toast notifications container */}
      <ToastContainer />
         {/* Loading Overlay */}
      <LoadingOverlay open={loading} />
      {/* Conditionally render Navbar */}
      {!hideNavbarRoutes.includes(location.pathname) && <Navbar />}
        <Routes>
        <Route
            path="/"
             element={
               <>
                 <VideoContainer />
                 {/* <ContentContainer /> */}
                  {/* <LanguageContainer /> */}
                  {/* <AvailabilityContainer /> */}
                  {/* <AnyIndustry /> */}
                  {/* <Expertise /> */}
                 {/* <FeaturesContainer /> */}
                 {/* <TestimonialsContainer /> */}
                 {/* <LinksContainer /> */}
                 {/* <ContactContainer /> */}
               </>
             }
           />
           <Route path="/about" element={<About />} />
           <Route path="/privacy" element={<Privacy />} />
           <Route path="/faq" element={<FAQ />} />
           <Route path="/terms-of-service" element={<Terms />} />
           <Route path="/industry/manufacturing" element={<Manufacturing />} />
            <Route path="/industry/landscaping" element={<Landscaping />} />
            <Route path="/industry/construction" element={<Construction />} />
            <Route path="/industry/restaurant" element={<Restaurant />} />
            <Route path="/industry/hospitality" element={<Hospitality />} />
            <Route path="/industry/healthcare" element={<Health />} />
            <Route path="/industry/oil-gas" element={<OilAndGas />} />
            <Route path="/industry/warehouse" element={<Warehouse />} />
            <Route path="/industry/logistics" element={<Logistics />} />
            <Route path="/case-study" element={<CaseStudy />} />
            <Route path="/interview-screen" element={<InterviewSC />} />
            <Route path="/interview-screen/checklist" element={<Checklist />} />
            <Route path="/interview-screen/compliance" element={<Compliance />} />
            <Route path="/interview-screen/workflow-logistics" element={<Workflow />} />
            <Route path="/product" element={<Product />} />
           <Route path="/contact" element={<Contact />} />
           <Route path="/login" element={<Login />} /> 
           {/* <Route path="/login" element={<Navigate to="/" replace />} />          */}
           {/* <Route path="/register" element={<Register />} /> */}
           <Route path="/register" element={<Navigate to="/" replace />} />
          <Route path="/blog" element={<BlogList />}/> 
          {/* Protect the admin dashboard route */}
          <Route 
            path="/admin-dashboard" 
            element={
              <ProtectedRoute requireAdmin={true}>
                <AdminDashboard />
              </ProtectedRoute>
            } 
          />
           <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/agents-overview" 
          element={
            <ProtectedRoute>
              <AgentsOverview />
            </ProtectedRoute>
          } 
        />
         <Route path="*" element={<ErrorPage />} />
        </Routes>
        {!hideFooterRoutes.includes(location.pathname) && <Footer />}
      </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}


export default AppWrapper;