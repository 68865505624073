import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from './Copyright';
import ChartUserByCountry from './ChartUserByCountry';
import CustomizedTreeView from './CustomizedTreeView';
import CustomizedDataGrid from './CustomizedDataGrid';
//import HighlightedCard from './HighlightedCard';
import PageViewsBarChart from './PageViewsBarChart';
import SessionsChart from './SessionsChart';
import StatCard from './StatCard';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import SearchBar from './SearchBar';
import { fetchInboundCalls } from '../../src/redux/actions/inboundCallsActions';
import { fetchOutboundCalls } from '../../src/redux/actions/outboundCallsActions';
import { fetchSignedUrl } from '../../src/redux/actions/inboundCallsActions';
import { fetchPdfSignedUrlRequest } from '../../src/redux/actions/inboundCallsActions';
import '../css/MainGrid.css';
import axiosInstance from '../../src/api/axios';



export default function MainGrid({ section }) {
  const navigate = useNavigate();
  const [agentCount, setAgentCount] = useState(0);
  const [totalInboundCallsThisMonth, setTotalInboundCallsThisMonth] = useState(0);
  const [totalOutboundCallsThisMonth, setTotalOutboundCallsThisMonth] = useState(0);
  const [totalLiveCallsThisMonth, setTotalLiveCallsThisMonth] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [agentsModalOpen, setAgentsModalOpen] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [inboundCallsModalOpen, setInboundCallsModalOpen] = useState(false);
  const [inboundCallsList, setInboundCallsList] = useState([]);
  const [outboundCallsModalOpen, setOutboundCallsModalOpen] = useState(false);
  const [outboundCallsList, setOutboundCallsList] = useState([]);
  const [liveCallsModalOpen, setLiveCallsModalOpen] = useState(false);
  const [liveCallsList, setLiveCallsList] = useState([]);
  const [inboundCalls, setInboundCalls] = useState([]);
  const [inboundCallsLoading, setInboundCallsLoading] = useState(true);
  const [inboundCallsError, setInboundCallsError] = useState(null);
  const [outboundCalls, setOutboundCalls] = useState([]);
  const [outboundCallsLoading, setOutboundCallsLoading] = useState(true);
  const [outboundCallsError, setOutboundCallsError] = useState(null);
  const [openModalCallId, setOpenModalCallId] = useState(null);
  const { calls, searchTerm, audioUrl, pdfUrl } = useSelector(state => state.inboundCalls);
  const [filterServiceModalOpen, setFilterServiceModalOpen] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [audioModalOpen, setAudioModalOpen] = useState(false);
  const [currentCall, setCurrentCall] = useState(null);
  const pageSize = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [inboundTotalPages, setInboundTotalPages] = useState(1);
  const [inboundSearchTerm, setInboundSearchTerm] = useState(''); // Renamed from searchTerm
  const [inboundHighlightedElements, setInboundHighlightedElements] = useState([]);
  const [highlightedTranscript, setHighlightedTranscript] = useState([]);
  const [allMatches, setAllMatches] = useState([]);

// Active match index (which match is currently “selected”)
const [activeMatchIndex, setActiveMatchIndex] = useState(0);
const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);



  const dispatch = useDispatch();

  const modalContentRef = useRef(null);

  useEffect(() => {
    if (!isLoggedIn) return;
    setTimeout(() => {
      const activeEl = document.getElementById('active-highlight');
      if (activeEl) {
        activeEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  }, [activeMatchIndex]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (pdfUrl) {
      window.location.href = pdfUrl; // triggers download
    }
  }, [pdfUrl]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (currentCall?.transcript_object) {
      setHighlightedTranscript(highlightText(currentCall.transcript_object, inboundSearchTerm));
    }
  }, [currentCall, inboundSearchTerm]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (section === 'inboundCalls') {
      dispatch(fetchInboundCalls());
      // console.log("i fetched inbound calls")
    }
    if (section === 'outboundCalls') {
      dispatch(fetchOutboundCalls());
      // console.log("i fetched outbound calls")
    }
  }, [section, dispatch]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (calls) {
      setInboundTotalPages(Math.ceil(calls.length / pageSize));
    }
  }, [calls]);

  const filteredCalls = calls
  .filter(call => {
    const fullName = call.custom_analysis?.full_name || '';
    const hearAboutUs = call.custom_analysis?.how_did_you_hear_about_us || '';
    const searchLower = searchTerm.toLowerCase();
    
    // Check if the service filter is active
    const serviceMatch = selectedServices.length === 0 || selectedServices.includes(call.service);

    // Filter by search term and service
    return (fullName.toLowerCase().includes(searchLower) || 
            hearAboutUs.toLowerCase().includes(searchLower)) && 
            serviceMatch;
  })
  .sort((a, b) => new Date(b.start_timestamp) - new Date(a.start_timestamp));

  const paginatedCalls = filteredCalls.slice(
    (currentPage - 1) * pageSize, 
    currentPage * pageSize
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= inboundTotalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleGoToAgents = () => {
    // Close modal first (optional), then navigate:
    setAgentsModalOpen(false);
    navigate('/agents-overview');
  };


  const handleFetchSignedUrl = (call_id) => {
    dispatch(fetchSignedUrl(call_id));
    // console.log("*************im fetching signed url!!!!!!!", call_id);
  };

  function handleFetchPdfUrl(call) {
    // e.stopPropagation(); // do in the onClick if needed
    dispatch(fetchPdfSignedUrlRequest(call.call_id));
  }
  

  const renderPagination = () => {
    return (
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button 
          onClick={() => handlePageChange(currentPage - 1)} 
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Typography sx={{ mx: 2 }}>{currentPage} of {inboundTotalPages}</Typography>
        <Button 
          onClick={() => handlePageChange(currentPage + 1)} 
          disabled={currentPage === inboundTotalPages}
        >
          Next
        </Button>
      </Box>
    );
  };

  function stripPunctuation(str) {
    // Remove all punctuation (.,!? etc.) and symbols, then lowercase.
    // \p{P} matches punctuation, \p{S} matches symbols (like $, %, etc.)
    // The 'u' flag is for Unicode.
    return str.replace(/[\p{P}\p{S}]+/gu, '').toLowerCase();
  }

  const handleFindNext = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (allMatches.length > 0) {
        // Move activeMatchIndex forward by 1, looping to 0 at the end
        setActiveMatchIndex((prev) => (prev + 1) % allMatches.length);
      }
    }
  }, [allMatches]);

  const highlightText = (transcript, searchTerm) => {
  if (!transcript) return [];

  // Strip punctuation and lowercase from the user's search
  const normalizedSearch = stripPunctuation(searchTerm);

  return transcript.map((seg) => ({
    ...seg,
    words: seg.words.map((wordObj) => {
      // Strip punctuation and lowercase from each word
      const normalizedWord = stripPunctuation(wordObj.word);
      // Decide if there's a match
      const isHighlighted =
        normalizedSearch &&
        normalizedWord.includes(normalizedSearch);

      return {
        ...wordObj,
        highlight: isHighlighted
      };
    })
  }));
};
  
  const handleSearch = (e) => {
    const term = e.target.value;
    setInboundSearchTerm(term);
  
    if (currentCall?.transcript_object) {
      // find all matches
      const matches = findAllMatches(currentCall.transcript_object, term);
      setAllMatches(matches);
      setActiveMatchIndex(0);
    }
  };
  
  // Helper that returns an array of all matched positions: 
  // [ { segIndex: number, wordIndex: number }, ... ]
  function findAllMatches(transcript, term) {
    // If no transcript or empty search term, return an empty array
    if (!Array.isArray(transcript) || !term) return [];
  
    const lowerTerm = term.toLowerCase();
    const results = [];
  
    transcript.forEach((seg, segIndex) => {
      // 1. If this segment has a 'words' array (typical), scan it
      if (Array.isArray(seg.words)) {
        seg.words.forEach((w, wIndex) => {
          if (w.word.toLowerCase().includes(lowerTerm)) {
            results.push({ segIndex, wordIndex: wIndex });
          }
        });
      }
      // 2. Optional fallback: if there’s a 'seg.content' string 
      //    (common if transcript came from plain text)
      else if (typeof seg.content === 'string') {
        if (seg.content.toLowerCase().includes(lowerTerm)) {
          // You might treat the entire segment as one match, or break it down further
          results.push({ segIndex, wordIndex: 0 });
        }
      }
    });
  
    return results;
  }
  

  useEffect(() => {
    if (!isLoggedIn) return;
    if (audioModalOpen) {
      document.addEventListener('keydown', handleFindNext);
    } else {
      document.removeEventListener('keydown', handleFindNext);
    }
    return () => document.removeEventListener('keydown', handleFindNext);
  }, [audioModalOpen, inboundHighlightedElements, handleFindNext]);

  useEffect(() => {
    if (!isLoggedIn) return;
    async function fetchDashboardData() {
      try {
        // const token = localStorage.getItem('token');
        // const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
  
        const response = await axiosInstance.get(`/api/dashboard`);
  
        const data = response.data;
        // console.log('Dashboard Data:', data);
  
        setAgentCount(data.agentCount);
        setTotalInboundCallsThisMonth(data.totalInboundCallsThisMonth);
        setTotalOutboundCallsThisMonth(data.totalOutboundCallsThisMonth);
        setTotalLiveCallsThisMonth(data.totalLiveCallsThisMonth);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError(err.message);
        setLoading(false);
      }
    }
  
    fetchDashboardData();
  }, [section]);

  useEffect(() => {
    if (!isLoggedIn) return;
  if (section === 'inboundCalls') {
    const fetchInboundCalls = async () => {
      try {
        setInboundCallsLoading(true);
        // const token = localStorage.getItem('token');
        // const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
        
        const response = await axiosInstance.get('/api/calls/inbound-calls-company-user');

        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
        
        const data = response.data;
        setInboundCalls(data);
        setInboundCallsLoading(false);
      } catch (err) {
        console.error('Error fetching inbound calls:', err);
        setInboundCallsError('Failed to load inbound calls');
        setInboundCallsLoading(false);
      }
    };

    fetchInboundCalls();
  }
}, [section]);

useEffect(() => {
  if (!isLoggedIn) return;
if (section === 'outboundCalls') {
  const fetchOutboundCalls = async () => {
    try {
      setOutboundCallsLoading(true);
      // const token = localStorage.getItem('token');
      // const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
      
      const response = await axiosInstance.get('/api/calls/outbound-calls-company-user');

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      
      const data = response.data;
      setOutboundCalls(data);
      setOutboundCallsLoading(false);
    } catch (err) {
      console.error('Error fetching inbound calls:', err);
      setOutboundCallsError('Failed to load inbound calls');
      setOutboundCallsLoading(false);
    }
  };

  fetchOutboundCalls();
}
}, [section]);


function normalizeForSearch(str) {
  // 1) Trim whitespace
  // 2) Convert to lowercase
  // 3) Strip punctuation from the edges
  const trimmed = str.trim().toLowerCase();
  return stripPunctuation(trimmed);
}


function createTranscriptDisplay(transcriptArr) {
  if (!Array.isArray(transcriptArr)) {
    return <p>No transcript data</p>;
  }

  return (
    <div>
      {transcriptArr.map((seg, segIndex) => (
        <div key={segIndex} style={{ marginBottom: '1rem' }}>
          <strong>{seg.role}:</strong>&nbsp;
          {Array.isArray(seg.words) ? (
            seg.words.map((w, wIndex) => {
              // Check if (segIndex, wIndex) is in the matches
              const isMatch = allMatches.some(
                (m) => m.segIndex === segIndex && m.wordIndex === wIndex
              );
              // Check if it’s active
              const matchObj = allMatches[activeMatchIndex];
              const isActive =
                matchObj?.segIndex === segIndex &&
                matchObj?.wordIndex === wIndex;

              const backgroundColor = isActive
                ? 'orange'
                : isMatch
                ? 'yellow'
                : 'transparent';

              return (
                <span
                  key={wIndex}
                  id={isActive ? 'active-highlight' : undefined}
                  style={{
                    backgroundColor,
                    color: '#f9f9f9',
                    marginRight: 4,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (Number.isFinite(w.start)) {
                      handleSeek(w.start);
                    }
                  }}
                >
                  {w.word}
                </span>
              );
            })
          ) : (
            // fallback if 'seg.words' doesn't exist, 
            // in case 'seg.content' holds text
            seg.content
          )}
        </div>
      ))}
    </div>
  );
}

  async function fetchAgents() {
    try {
      // const token = localStorage.getItem('token');
      // const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
      const response = await axiosInstance.get('/api/agents');
        
      const agentsData = response.data;
      setAgentList(agentsData);
    } catch (error) {
      console.error('Error fetching agents:', error);
      setAgentList([]); // fallback to empty list if error
    }
  }

  async function fetchInboundCallsThisMonth() {
    try {
      console.log("fetch inbound, fetch!!!!!!!!");
      // const token = localStorage.getItem('token');
      // const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
      // Backend endpoint that returns an array of inbound calls for this month:
      // [
      //   {
      //     call_id: '123',
      //     start_timestamp: '2024-12-05T10:00:00Z',
      //     duration_minutes: 5,
      //     custom_analysis: { name: 'John Doe', location: 'NY' },
      //   }
      // ]
      const response = await axiosInstance.get(`/api/calls/inbound-calls-this-month`);
      const callsData = response.data
  // Transform custom_analysis if needed
  .map(call => {
    const { full_name, how_did_you_hear_about_us } = call.custom_analysis || {};
    return {
      ...call,
      custom_analysis: {
        full_name,
        how_did_you_hear_about_us
      },
    };
  })
  // Sort descending by start_timestamp (newest first)
  .sort((a, b) => new Date(b.start_timestamp) - new Date(a.start_timestamp));
      

      setInboundCallsList(callsData);
    } catch (error) {
      console.error('Error fetching inbound calls this month:', error);
      setInboundCallsList([]);
    }
  }

  async function fetchOutboundCallsThisMonth() {
    try {
      // const token = localStorage.getItem('token');
      // const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
      const response = await axiosInstance.get(`/api/calls/outbound-calls-this-month`);
      const callsData = response.data;

      setOutboundCallsList(callsData);
    } catch (error) {
      console.error('Error fetching outbound calls this month:', error);
      setOutboundCallsList([]);
    }
  }

  async function fetchLiveCallsThisMonth() {
    try {
      // const token = localStorage.getItem('token');
      // const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
      const response = await axiosInstance.get(`/api/calls/live-calls-this-month`);
      const callsData = response.data;

      setLiveCallsList(callsData);
    } catch (error) {
      console.error('Error fetching live calls this month:', error);
      setLiveCallsList([]);
    }
  }

  if (loading) {
    return <Typography variant="h6">Loading data...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" color="error">Error: {error}</Typography>;
  }

  const data = [
    {
      title: 'Agents',
      value: agentCount != null ? agentCount.toString() : '0',
      interval: '',
      trend: 'none',
      data: [],
      onClick: async () => {
        await fetchAgents(); 
        setAgentsModalOpen(true);
      }
    },
    {
      title: 'Inbound Calls',
      value: totalInboundCallsThisMonth != null ? totalInboundCallsThisMonth.toString() : '0',
      interval: 'This Month',
      trend: 'none',
      data: [],
      onClick: async () => {
        await fetchInboundCallsThisMonth();
        setInboundCallsModalOpen(true);
      }
    },
    {
      title: 'Outbound Calls',
      value: totalOutboundCallsThisMonth != null ? totalOutboundCallsThisMonth.toString() : '0',
      interval: 'This Month',
      trend: 'none',
      data: [],
      onClick: async () => {
        await fetchOutboundCallsThisMonth();
        setOutboundCallsModalOpen(true);
      }
    },
    {
      title: 'Live Calls',
      value: totalLiveCallsThisMonth != null ? totalLiveCallsThisMonth.toString() : '0',
      interval: 'This Month',
      trend: 'none',
      data: [],
      onClick: async () => {
        await fetchLiveCallsThisMonth();
        setLiveCallsModalOpen(true);
      }
    },
  ];

  function getInboundCallsContent() {
    if (inboundCallsLoading) return <Typography>Loading...</Typography>;
    if (inboundCallsError) return <Typography color="error">{inboundCallsError}</Typography>;
  
    return (
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          Inbound Calls Overview
        </Typography>
        <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
          <Grid size={12}>
          <SearchBar />
          <Button sx={{backgroundColor: 'blue !important', marginLeft: '30px !important'}} onClick={() => setFilterServiceModalOpen(true)}>Filter Service</Button>
          <Modal
            open={filterServiceModalOpen}
            onClose={() => setFilterServiceModalOpen(false)}
            aria-labelledby="filter-service-modal-title"
          >
            <Box sx={{
              position: 'absolute', 
              top: '50%', 
              left: '50%', 
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxHeight: '80vh', 
              overflowY: 'auto',
              width: 400
            }}>
              <Typography id="filter-service-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                Filter by Service
              </Typography>
              <FormControl component="fieldset">
                <FormGroup>
                  {['Job Application and Screening', 'Screening', 'Schedule', 'Interview', 'Verify', 'Assess', 'Onboard', 'Role-Play', 'Train', 'Performance Management'].map(service => (
                    <FormControlLabel 
                      key={service}
                      control={
                        <Checkbox 
                          checked={selectedServices.includes(service)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedServices([...selectedServices, service]);
                            } else {
                              setSelectedServices(selectedServices.filter(s => s !== service));
                            }
                          }}
                        />
                      }
                      label={service}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <Button onClick={() => setFilterServiceModalOpen(false)}>Apply Filter</Button>
            </Box>
          </Modal>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="inbound calls table">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Caller Name</strong></TableCell>
                    <TableCell><strong>Source</strong></TableCell>
                    <TableCell><strong>Service</strong></TableCell>
                    <TableCell><strong>Company Name</strong></TableCell>
                    <TableCell><strong>Time of Call</strong></TableCell>
                    <TableCell><strong>Duration (min)</strong></TableCell>
                    <TableCell><strong>From Number</strong></TableCell> 
                    <TableCell><strong>PDF</strong></TableCell>
                    <TableCell><strong>AudioTranscript</strong></TableCell>    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedCalls.map((call) => (
                    <TableRow 
                      key={call.id} 
                      onClick={() => {
                        setOpenModalCallId(call.id);
                        // console.log('Setting modal open for call:', call.id);
                      }} 
                      sx={{ '&:hover': { backgroundColor: 'action.hover' }, cursor: 'pointer' }}
                    >
                      {/* <TableCell>{call.id}</TableCell> */}
                      <TableCell>
                      {call.custom_analysis?.full_name || 'N/A'} {/* Only show full name */}
                    </TableCell>
                    <TableCell>{call.custom_analysis?.how_did_you_hear_about_us || 'N/A'}</TableCell> 
                    <TableCell>{call.service || 'N/A'}</TableCell>
                      <TableCell>{call.company_name}</TableCell>
                      <TableCell>{new Date(call.start_timestamp).toLocaleString()}</TableCell>
                      <TableCell>{call.duration_minutes}</TableCell>
                      <TableCell>{call.from_number || 'N/A'}</TableCell>
                       {/* NEW PDF cell */}
                      <TableCell>
                        <Button 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFetchPdfUrl(call); // We'll define this function below
                          }}
                          variant="outlined"
                          color="secondary"
                        >
                          Download PDF
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button 
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentCall(call);
                            handleFetchSignedUrl(call.call_id);
                            setAudioModalOpen(true);
                          }}
                          variant="outlined"
                          // className='ls40-x'
                          sx={{ backgroundColor: '#560598 !important' }}
                        >
                          AudioTranscript
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {renderPagination()}
          </Grid>
        </Grid>
        {currentCall && console.log("currentCall object:", currentCall)}
        <Modal
          open={audioModalOpen}
          onClose={() => setAudioModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box ref={modalContentRef} id="modalContent" sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 800,
            maxHeight: '80vh',
            overflowY: 'auto'
          }}>
            <TextField 
          label="Search within transcript"
          value={inboundSearchTerm}
          onChange={handleSearch}
          onKeyDown={handleFindNext}
          fullWidth
          sx={{ mb: 2 }}
        />
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
              Audio Transcript for Caller: {currentCall?.custom_analysis.full_name || 'Unknown'}
            </Typography>
            {audioUrl && (
              <audio id="audio-player" controls>
                <source src={audioUrl} type="audio/wav"/>
                Your browser does not support the audio element.
              </audio>
            )}
            <div id="transcript" style={{ marginTop: '15px' }}>
            {createTranscriptDisplay(highlightedTranscript)}
            </div>
          </Box>
        </Modal>
      </Box>
    );
  }

  // 1) The function that renders the transcript array:
  // function createTranscriptDisplay(transcriptArr) {
  //   // 1) If transcriptArr isn’t an array, show a fallback
  //   if (!Array.isArray(transcriptArr)) {
  //     return <p>No transcript data</p>;
  //   }
  
  //   // 2) Map over each "segment" in the transcript array
  //   return (
  //     <div>
  //       {transcriptArr.map((seg, segIndex) => (
  //         <div key={segIndex} style={{ marginBottom: '1rem' }}>
  //           {/* Display the speaker role: "agent" or "user" */}
  //           <strong>{seg.role}:</strong>&nbsp;
            
  //           {/* 3) If seg.words is an array, iterate each word */}
  //           {Array.isArray(seg.words) ? (
  //             seg.words.map((w, wIndex) => {
  //               // Log each word in the console to see the shape
  //               console.log("Word object:", w);
  
  //               return (
  //                 <span
  //                   key={wIndex}
  //                   style={{
  //                     color: '#f9f9f9',
  //                     cursor: 'pointer',
  //                     marginRight: '3px',
  //                   }}
  //                   // 4) On click, jump audio to w.start (if valid)
  //                   onClick={() => {
  //                     if (Number.isFinite(w.start)) {
  //                       handleSeek(w.start);
  //                     } else {
  //                       console.warn(
  //                         "No valid start for word:",
  //                         w.word,
  //                         "->",
  //                         w.start
  //                       );
  //                     }
  //                   }}
  //                 >
  //                   {w.word}
  //                 </span>
  //               );
  //             })
  //           ) : (
  //             // If no words array, just display seg.content
  //             <span>{seg.content}</span>
  //           )}
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }
  
  // The helper that sets the audio's currentTime
  function handleSeek(seconds) {
    const audio = document.getElementById('audio-player');
    if (audio) {
      audio.currentTime = seconds;
      audio.play();
    } else {
      console.warn("Couldn't find <audio> element with id='audio-player'");
    }
  }
  // Example time formatter
  function formatSeconds(seconds) {
    const min = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60).toString().padStart(2, '0');
    return `${min}:${sec}`;
  }

  function getMonthlyMetricsContent() {
    return (
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          Overview
        </Typography>
        <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
          {data.map((card, index) => (
            <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
              <StatCard {...card} />
            </Grid>
          ))}
          {/* You can comment out charts or other components until you’re ready to use them */}
          <Grid size={{ xs: 12, md: 6 }}>
            <SessionsChart />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <PageViewsBarChart />
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, lg: 3 }}>
            <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
               {/* <CustomizedTreeView /> */}
               <ChartUserByCountry />
             </Stack>
           </Grid>
        {/* Additional sections can remain commented out or left intact for future use */}
        <Copyright sx={{ my: 4 }} />
        {/* Modal for showing agents */}
        <Modal
    open={agentsModalOpen}
    onClose={() => setAgentsModalOpen(false)}
    aria-labelledby="agents-modal-title"
    aria-describedby="agents-modal-description"
  >
    <Box sx={{
      position: 'absolute', top: '50%', left: '50%',
      transform: 'translate(-50%, -50%)', bgcolor: 'background.paper',
      p: 4, borderRadius: 1, boxShadow: 24, width: 400,
    }}>
      <Typography id="agents-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
        Your Agents
      </Typography>
      {agentList.length === 0 ? (
        <Typography variant="body2">No Agents Available</Typography>
      ) : (
        <TableContainer>
          <Table size="small" aria-label="agents table">
            <TableHead>
              <TableRow>
                <TableCell><strong>Alias</strong></TableCell>
                {/* <TableCell><strong>ID</strong></TableCell> */}
                <TableCell><strong>Service</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agentList.map((agent) => (
                <TableRow key={agent.agent_id}>
                  <TableCell>{agent.alias || agent.agent_id}</TableCell>
                  {/* <TableCell>{agent.agent_id}</TableCell> */}
                  <TableCell>{agent.service || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box sx={{ mt: 2, textAlign: 'right' }}>
      <Button onClick={handleGoToAgents} variant="contained" sx={{ mr: 1 }}>
            Go To Agents
          </Button>
        <Button onClick={() => setAgentsModalOpen(false)}>Close</Button>
      </Box>
    </Box>
  </Modal>
   {/* Inbound Calls Modal */}
   <Modal
          open={inboundCallsModalOpen}
          onClose={() => setInboundCallsModalOpen(false)}
          aria-labelledby="inbound-calls-modal-title"
          aria-describedby="inbound-calls-modal-description"
        >
          <Box sx={{
            position: 'absolute', top: '50%', left: '50%',
            transform: 'translate(-50%, -50%)', bgcolor: 'background.paper',
            p: 4, borderRadius: 1, boxShadow: 24, width: 800,
          }}>
            <Typography id="inbound-calls-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
              Inbound Calls (This Month)
            </Typography>
            {inboundCallsList.length === 0 ? (
              <Typography variant="body2">No Inbound Calls Available</Typography>
            ) : (
              <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Time of call</strong></TableCell>
                      <TableCell><strong>Call Length</strong></TableCell>
                      <TableCell><strong>Phone Number</strong></TableCell>
                      <TableCell><strong>User Info</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inboundCallsList.map((call) => {
                      const timeOfCall = new Date(call.start_timestamp).toLocaleString();
                      const callLength = `${call.duration_minutes} min`;
                      const phoneNumber = call.from_number || 'N/A';
                      const userInfoObj = call.custom_analysis || {};
                      return (
                        <TableRow key={call.call_id}>
                          <TableCell>{timeOfCall}</TableCell>
                          <TableCell>{callLength}</TableCell>
                          <TableCell>{phoneNumber}</TableCell>
                          <TableCell>
          {/* We'll format user_info nicely here */}
          {Object.keys(userInfoObj).length === 0 
            ? 'No Info' 
            : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                {Object.entries(userInfoObj).map(([key, val]) => (
                  <Typography variant="body2" key={key}>
                    <strong>{key}</strong>: {String(val)}
                  </Typography>
                ))}
              </Box>
            )
          }
        </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button onClick={() => setInboundCallsModalOpen(false)}>Close</Button>
            </Box>
          </Box>
        </Modal>
         {/* Outbound Calls Modal */}
         <Modal
          open={outboundCallsModalOpen}
          onClose={() => setOutboundCallsModalOpen(false)}
          aria-labelledby="outbound-calls-modal-title"
          aria-describedby="outbound-calls-modal-description"
        >
          <Box sx={{
            position: 'absolute', top: '50%', left: '50%',
            transform: 'translate(-50%, -50%)', bgcolor: 'background.paper',
            p: 4, borderRadius: 1, boxShadow: 24, width: 500,
          }}>
            <Typography id="outbound-calls-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
              Outbound Calls (This Month)
            </Typography>
            {outboundCallsList.length === 0 ? (
              <Typography variant="body2">No Outbound Calls Available</Typography>
            ) : (
              <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Time of call</strong></TableCell>
                      <TableCell><strong>Call Length</strong></TableCell>
                      <TableCell><strong>User Info</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {outboundCallsList.map((call) => {
                      const timeOfCall = new Date(call.start_timestamp).toLocaleString();
                      const callLength = `${call.duration_minutes} min`;
                      const userInfo = JSON.stringify(call.custom_analysis) || 'N/A';
                      return (
                        <TableRow key={call.call_id}>
                          <TableCell>{timeOfCall}</TableCell>
                          <TableCell>{callLength}</TableCell>
                          <TableCell>{userInfo}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button onClick={() => setOutboundCallsModalOpen(false)}>Close</Button>
            </Box>
          </Box>
        </Modal>
         {/* Live Calls Modal */}
         <Modal
          open={liveCallsModalOpen}
          onClose={() => setLiveCallsModalOpen(false)}
          aria-labelledby="live-calls-modal-title"
          aria-describedby="live-calls-modal-description"
        >
          <Box sx={{
            position: 'absolute', top: '50%', left: '50%',
            transform: 'translate(-50%, -50%)', bgcolor: 'background.paper',
            p: 4, borderRadius: 1, boxShadow: 24, width: 500,
          }}>
            <Typography id="live-calls-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
              Live Calls (This Month)
            </Typography>
            {liveCallsList.length === 0 ? (
              <Typography variant="body2">No Live Calls Available</Typography>
            ) : (
              <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Time of call</strong></TableCell>
                      <TableCell><strong>Call Length</strong></TableCell>
                      <TableCell><strong>User Info</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {liveCallsList.map((call) => {
                      const timeOfCall = new Date(call.start_timestamp).toLocaleString();
                      const callLength = `${call.duration_minutes} min`;
                      const userInfo = JSON.stringify(call.custom_analysis) || 'N/A';
                      return (
                        <TableRow key={call.call_id}>
                          <TableCell>{timeOfCall}</TableCell>
                          <TableCell>{callLength}</TableCell>
                          <TableCell>{userInfo}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button onClick={() => setLiveCallsModalOpen(false)}>Close</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      
    );
  }

  const getAdminContent = () => {
    return (
      <>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          Admin Dashboard
        </Typography>
        <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
          <Grid size={12}>
            <Typography>This feature coming soon!</Typography>
          </Grid>
        </Grid>
        <Copyright sx={{ my: 4 }} />
      </>
    );
  };

  const getVettedVoiceAssistant = () => {
    return (
      <>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          VettedVoice Assistant
        </Typography>
        <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
          <Grid size={12}>
            <Typography>This feature is coming soon!</Typography>
          </Grid>
        </Grid>
        <Copyright sx={{ my: 4 }} />
      </>
    );
  };

  const getLiveCallsContent = () => {
    return (
      <>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          Live Calls
        </Typography>
        <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
          <Grid size={12}>
            <Typography>This feature coming soon!</Typography>
          </Grid>
        </Grid>
        <Copyright sx={{ my: 4 }} />
      </>
    );
  };

  const getOutboundCallsContent = () => {
    if (outboundCallsLoading) {
      return <Typography>Loading...</Typography>;
    }
  
    if (outboundCallsError) {
      return <Typography color="error">{outboundCallsError}</Typography>;
    }
  
    // 1) If we have no calls, display "No calls available"
    if (!outboundCalls || outboundCalls.length === 0) {
      return <Typography>No calls available</Typography>;
    }
  
    // 2) Otherwise, render the table
    return (
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          Outbound Calls Overview
        </Typography>
        <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
          <Grid size={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="inbound calls table">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Name</strong></TableCell>
                    <TableCell><strong>Service</strong></TableCell>
                    <TableCell><strong>Company Name</strong></TableCell>
                    <TableCell><strong>Time of Call</strong></TableCell>
                    <TableCell><strong>Duration (min)</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedCalls.map((call) => (
                    <TableRow 
                      key={call.id}
                      onClick={() => setOpenModalCallId(call.id)}
                      sx={{ '&:hover': { backgroundColor: 'action.hover' }, cursor: 'pointer' }}
                    >
                      <TableCell>{call.custom_analysis?.full_name || 'N/A'}</TableCell>
                      <TableCell>{call.service || 'N/A'}</TableCell>
                      <TableCell>{call.company_name}</TableCell>
                      <TableCell>{new Date(call.start_timestamp).toLocaleString()}</TableCell>
                      <TableCell>{call.duration_minutes}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {renderPagination()}
          </Grid>
        </Grid>
      </Box>
    );
  };

  // const getOutboundCallsContent = () => {
  //   if (outboundCallsLoading) return <Typography>Loading...</Typography>;
  //   if (outboundCallsError) return <Typography color="error">{outboundCallsError}</Typography>;

  //   return (
  //     <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
  //       <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
  //         Outbound Calls Overview
  //       </Typography>
  //       <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
  //         <Grid size={12}>
  //         {/* <SearchBar /> */}
  //         {/* <Button sx={{backgroundColor: 'blue !important', marginLeft: '30px !important'}} onClick={() => setFilterServiceModalOpen(true)}>Filter Service</Button> */}
  //         {/* <Modal
  //           open={filterServiceModalOpen}
  //           onClose={() => setFilterServiceModalOpen(false)}
  //           aria-labelledby="filter-service-modal-title"
  //         >
  //           <Box sx={{
  //             position: 'absolute', 
  //             top: '50%', 
  //             left: '50%', 
  //             transform: 'translate(-50%, -50%)',
  //             bgcolor: 'background.paper',
  //             boxShadow: 24,
  //             p: 4,
  //             maxHeight: '80vh', 
  //             overflowY: 'auto',
  //             width: 400
  //           }}>
  //             <Typography id="filter-service-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
  //               Filter by Service
  //             </Typography>
  //             <FormControl component="fieldset">
  //               <FormGroup>
  //                 {['Job Reference', 'Employment Verification', 'Interviewing', 'Screening', 'Schedule'].map(service => (
  //                   <FormControlLabel 
  //                     key={service}
  //                     control={
  //                       <Checkbox 
  //                         checked={selectedServices.includes(service)}
  //                         onChange={(e) => {
  //                           if (e.target.checked) {
  //                             setSelectedServices([...selectedServices, service]);
  //                           } else {
  //                             setSelectedServices(selectedServices.filter(s => s !== service));
  //                           }
  //                         }}
  //                       />
  //                     }
  //                     label={service}
  //                   />
  //                 ))}
  //               </FormGroup>
  //             </FormControl>
  //             <Button onClick={() => setFilterServiceModalOpen(false)}>Apply Filter</Button>
  //           </Box>
  //         </Modal> */}
  //           <TableContainer component={Paper}>
  //             <Table sx={{ minWidth: 650 }} aria-label="inbound calls table">
  //               <TableHead>
  //                 <TableRow>
  //                   <TableCell><strong>Name</strong></TableCell>
  //                   {/* <TableCell><strong>Source</strong></TableCell> */}
  //                   <TableCell><strong>Service</strong></TableCell>
  //                   <TableCell><strong>Company Name</strong></TableCell>
  //                   <TableCell><strong>Time of Call</strong></TableCell>
  //                   <TableCell><strong>Duration (min)</strong></TableCell>
  //                   {/* <TableCell><strong>From Number</strong></TableCell>  */}
  //                   {/* <TableCell><strong>AudioTranscript</strong></TableCell>     */}
  //                 </TableRow>
  //               </TableHead>
  //               <TableBody>
  //                 {paginatedCalls.map((call) => (
  //                   <TableRow 
  //                     key={call.id} 
  //                     onClick={() => {
  //                       setOpenModalCallId(call.id);
  //                       // console.log('Setting modal open for call:', call.id);
  //                     }} 
  //                     sx={{ '&:hover': { backgroundColor: 'action.hover' }, cursor: 'pointer' }}
  //                   >
  //                     {/* <TableCell>{call.id}</TableCell> */}
  //                     <TableCell>
  //                     {call.custom_analysis?.full_name || 'N/A'} {/* Only show full name */}
  //                   </TableCell>
  //                   <TableCell>{call.service || 'N/A'}</TableCell>
  //                     <TableCell>{call.company_name}</TableCell>
  //                     <TableCell>{new Date(call.start_timestamp).toLocaleString()}</TableCell>
  //                     <TableCell>{call.duration_minutes}</TableCell>
  //                     {/* <TableCell>{call.from_number || 'N/A'}</TableCell> */}
  //                     {/* <TableCell>
  //                       <Button 
  //                         onClick={(e) => {
  //                           e.stopPropagation();
  //                           setCurrentCall(call);
  //                           handleFetchSignedUrl(call.call_id);
  //                           setAudioModalOpen(true);
  //                         }}
  //                         variant="outlined"
  //                         // className='ls40-x'
  //                         sx={{ backgroundColor: '#560598 !important' }}
  //                       >
  //                         AudioTranscript
  //                       </Button> */}
  //                     {/* </TableCell> */}
  //                   </TableRow>
  //                 ))}
  //               </TableBody>
  //             </Table>
  //           </TableContainer>
  //           {renderPagination()}
  //         </Grid>
  //       </Grid>
  //       {/* {currentCall && console.log("currentCall object:", currentCall)}
  //       <Modal
  //         open={audioModalOpen}
  //         onClose={() => setAudioModalOpen(false)}
  //         aria-labelledby="modal-modal-title"
  //         aria-describedby="modal-modal-description"
  //       >
  //         <Box ref={modalContentRef} id="modalContent" sx={{
  //           position: 'absolute',
  //           top: '50%',
  //           left: '50%',
  //           transform: 'translate(-50%, -50%)',
  //           bgcolor: 'background.paper',
  //           boxShadow: 24,
  //           p: 4,
  //           width: 800,
  //           maxHeight: '80vh',
  //           overflowY: 'auto'
  //         }}>
  //           <TextField 
  //         label="Search within transcript"
  //         value={inboundSearchTerm}
  //         onChange={handleSearch}
  //         onKeyDown={handleFindNext}
  //         fullWidth
  //         sx={{ mb: 2 }}
  //       />
  //           <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
  //             Audio Transcript for Caller: {currentCall?.custom_analysis.full_name || 'Unknown'}
  //           </Typography>
  //           {audioUrl && (
  //             <audio id="audio-player" controls>
  //               <source src={audioUrl} type="audio/wav"/>
  //               Your browser does not support the audio element.
  //             </audio>
  //           )}
  //           <div id="transcript" style={{ marginTop: '15px' }}>
  //           {createTranscriptDisplay(highlightedTranscript)}
  //           </div>
  //         </Box>
  //       </Modal> */}
  //     </Box>
  //   );
  // };

  const renderContent = () => {
    switch (section) {
      case 'admin':
        return getAdminContent();
      case 'monthlyMetrics':
        return getMonthlyMetricsContent();
      case 'inboundCalls':
        return getInboundCallsContent(filteredCalls);
      case 'outboundCalls':
        return getOutboundCallsContent();
      case 'liveCalls':
        return getLiveCallsContent();
      case 'vettedvoiceAssistant':
        return getVettedVoiceAssistant();
      default:
        return (
          <Typography variant="h4">
            Unknown Section. Please select a valid section.
          </Typography>
        );
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {renderContent()}
      <Modal
        open={openModalCallId !== null}
        onClose={() => {
          setOpenModalCallId(null);
          // console.log('Closing modal');
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box 
          sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            bgcolor: 'background.paper', 
            boxShadow: 24, 
            p: 4, 
            maxHeight: '80vh', 
            overflowY: 'auto',
            width: 500,
            zIndex: 1300  
          }}
        >
          {(() => {
            const call = inboundCalls.find(call => call.id === openModalCallId);
            return (
              <>
                <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                  Details for Caller: {call?.custom_analysis.full_name || 'Unknown'}
                </Typography>
                {/* <Typography variant="body2">
                  <strong>ID:</strong> {call?.id}
                </Typography> */}
                 <Typography variant="body2" sx={{ mt: 2 }}>
                  <strong>Caller Info:</strong>
                </Typography>
                {call?.custom_analysis && Object.entries(call.custom_analysis).map(([key, value]) => (
                  <Typography key={key} variant="body2" sx={{ ml: 2 }}>
                    <strong>{key.replace(/_/g, ' ')}:</strong> {value}
                  </Typography>
                ))}
                <Typography variant="body2">
                  <strong>Company Name:</strong> {call?.company_name}
                </Typography>
                <Typography variant="body2">
                  <strong>Time of Call:</strong> {call ? new Date(call.start_timestamp).toLocaleString() : 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Duration (min):</strong> {call?.duration_minutes}
                </Typography>
                <Typography variant="body2">
                  <strong>From Number:</strong> {call?.from_number || 'N/A'}
                </Typography>
               
              </>
            );
          })()}
        </Box>
      </Modal>
    </Box>
  );
}
