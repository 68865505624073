import { call, put, takeLatest, all } from 'redux-saga/effects';
// import axios from 'axios';
import axiosInstance from '../../api/axios';
import { authSuccess, authFailed, logoutSuccess } from '../actions/authActions';

// Helper to set token in Axios headers and localStorage
function setAuthToken(token) {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('token', token); // Store token in localStorage
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
    localStorage.removeItem('token'); // Remove token from localStorage
  }
}

// Helper to get the correct API base URL for production or development
const getApiBaseUrl = () => {
  // return process.env.NODE_ENV === 'production'
  //   ? 'https://your-production-url.com'
  //   : 'http://localhost:5000';
  const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
  console.log('In sagas, this is API URL:', fetchUrl);
  return fetchUrl;
};

// Unified auth handler for both login and registration
function* handleAuth(action) {
  const { credentials, actionType } = action.payload;
  const apiUrl = getApiBaseUrl(); // Get the correct API base URL
  const endpoint = actionType === 'login' ? '/auth/login' : '/auth/register';

  try {
    const response = yield call(axiosInstance.post, `${apiUrl}${endpoint}`, credentials);

    const { user, token } = response.data; // Extract user and token from response
    // console.log('Auth Response:', response.data);

    // Store token and user in localStorage for persistence
    setAuthToken(token);
    localStorage.setItem('user', JSON.stringify(user));

    // Dispatch success action with user and token to Redux
    yield put(authSuccess({ user, token }));
  } catch (error) {
    console.error('Authentication failed:', error);
    yield put(authFailed(error.response?.data?.message || 'Authentication failed.'));
  }
}

function* handleLogout() {
  console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Logging out...');
  // Clear the token from axios headers
  setAuthToken(null); 

  // Remove user and token from localStorage explicitly
  localStorage.removeItem('user');
  localStorage.removeItem('token');

  // Now dispatch the logout success action, which resets the Redux state
  yield put(logoutSuccess());
}

function* handleTokenExpired() {
  // Clear token and user data
  setAuthToken(null);
  localStorage.removeItem('user');
  
  // Dispatch the action to update the Redux store
  yield put(logoutSuccess());
}

// Watcher saga for authentication and logout actions
export default function* authSaga() {
  yield all([
    takeLatest('AUTH_REQUESTED', handleAuth),
    takeLatest('LOGOUT_REQUESTED', handleLogout),
    takeLatest('TOKEN_EXPIRED', handleTokenExpired),
  ]);
}
