import * as React from 'react';
//import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InsightsIcon from '@mui/icons-material/Insights';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AssistantIcon from '@mui/icons-material/Assistant';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

const mainListItems = [
  { text: 'Admin', icon: <HomeRoundedIcon />, section: 'admin' },
  { text: 'Monthly Metrics', icon: <InsightsIcon />, section: 'monthlyMetrics' },
  { text: 'Inbound Calls', icon: <PhoneCallbackIcon />, section: 'inboundCalls' },
  { text: 'Outbound Calls', icon: <PhoneForwardedIcon />, section: 'outboundCalls' },
  { text: 'Live Calls', icon: <SmartphoneIcon />, section: 'liveCalls' },
  { text: 'VettedVoice Assistant', icon: <AssistantIcon />, section: 'vettedvoiceAssistant' },
];

const secondaryListItems = [
  // { text: 'Settings', icon: <SettingsRoundedIcon /> },
  // // { text: 'About', icon: <InfoRoundedIcon /> },
  // { text: 'Feedback', icon: <HelpRoundedIcon /> },
];

export default function MenuContent({ user, setActiveSection }) {

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton onClick={() => setActiveSection(item.section)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton onClick={() => setActiveSection(item.section)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}