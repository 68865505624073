//registerSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
//import axios from 'axios';
import axiosInstance from '../../api/axios';
import { registerSuccess, registerFailed } from '../actions/registerActions';

// Helper function to set the token in Axios headers
function setAuthToken(token) {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
}

function* register(action) {
  try {
    // Fetch the API URL and perform the registration in one function
    const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
    console.log('In sagas, this is API URL:', fetchUrl);

    // Fetch the API configuration if needed
    const configResponse = yield call(axiosInstance.get, `${fetchUrl}/config`);
    const apiUrl = configResponse.data.apiUrl;
    console.log('Fetched API URL from config:', apiUrl);

    // Perform registration using the fetched API URL
    const response = yield call(axiosInstance.post, `${apiUrl}/auth/register`, action.payload);
    console.log('I got posted in sagas to /auth/register');

    const { token } = response.data; // Assume backend returns a JWT token
    localStorage.setItem('token', token); // Store the token in localStorage

    // Set the token in Axios headers for future requests
    setAuthToken(token);

    // Dispatch the success action
    yield put(registerSuccess(response.data));
  } catch (error) {
    console.error('Registration failed:', error);

    // Dispatch the failure action with error message
    yield put(registerFailed(error.response?.data?.message || 'Registration failed.'));
  }
}

// Watcher saga for the registration action
export default function* registerSaga() {
  yield takeLatest('REGISTER_REQUESTED', register);
}
