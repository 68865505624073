// import * as React from 'react';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Chip from '@mui/material/Chip';
// import Typography from '@mui/material/Typography';
// import Stack from '@mui/material/Stack';
// import { BarChart } from '@mui/x-charts/BarChart';
// import { useTheme } from '@mui/material/styles';

// export default function PageViewsBarChart() {
//   const theme = useTheme();
//   const colorPalette = [
//     (theme.vars || theme).palette.primary.dark,
//     (theme.vars || theme).palette.primary.main,
//     (theme.vars || theme).palette.primary.light,
//   ];

//   return (
//     <Card variant="outlined" sx={{ width: '100%' }}>
//       <CardContent>
//         <Typography component="h2" variant="subtitle2" gutterBottom>
//           Events Per Month
//         </Typography>
//         <Stack sx={{ justifyContent: 'space-between' }}>
//           <Stack
//             direction="row"
//             sx={{
//               alignContent: { xs: 'center', sm: 'flex-start' },
//               alignItems: 'center',
//               gap: 1,
//             }}
//           >
//             <Typography variant="h4" component="p">
//               1.3M
//             </Typography>
//             <Chip size="small" color="error" label="-8%" />
//           </Stack>
//           <Typography variant="caption" sx={{ color: 'text.secondary' }}>
//             Page views and downloads for the last 6 months
//           </Typography>
//         </Stack>
//         <BarChart
//           borderRadius={8}
//           colors={colorPalette}
//           xAxis={[
//             {
//               scaleType: 'band',
//               categoryGapRatio: 0.5,
//               data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
//             },
//           ]}
//           series={[
//             {
//               id: 'page-views',
//               label: 'Page views',
//               data: [2234, 3872, 2998, 4125, 3357, 2789, 2998],
//               stack: 'A',
//             },
//             {
//               id: 'downloads',
//               label: 'Downloads',
//               data: [3098, 4215, 2384, 2101, 4752, 3593, 2384],
//               stack: 'A',
//             },
//             {
//               id: 'conversions',
//               label: 'Conversions',
//               data: [4051, 2275, 3129, 4693, 3904, 2038, 2275],
//               stack: 'A',
//             },
//           ]}
//           height={250}
//           margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
//           grid={{ horizontal: true }}
//           slotProps={{
//             legend: {
//               hidden: true,
//             },
//           }}
//         />
//       </CardContent>
//     </Card>
//   );
// }

import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import axiosInstance from '../../src/api/axios';
import { useSelector } from 'react-redux';

export default function MonthlyCallsChart() {
  const [data, setData] = useState(null);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) return;
    const fetchData = async () => {
      const response = await axiosInstance.get('/api/dashboard/monthly-totals');
        const result = response.data;
      setData(result);
    };
    fetchData();
  }, []);

  if (!data) {
    return <Typography>Loading...</Typography>;
  }

  // Convert inboundCallsByMonth object to an array
  const inboundDataArray = [
    data.inboundCallsByMonth.january || 0,
    data.inboundCallsByMonth.february || 0,
    data.inboundCallsByMonth.march || 0,
    data.inboundCallsByMonth.april || 0,
    data.inboundCallsByMonth.may || 0,
    data.inboundCallsByMonth.june || 0,
    data.inboundCallsByMonth.july || 0,
    data.inboundCallsByMonth.august || 0,
    data.inboundCallsByMonth.september || 0,
    data.inboundCallsByMonth.october || 0,
    data.inboundCallsByMonth.november || 0,
    data.inboundCallsByMonth.december || 0
  ];

  // Convert outboundCallsByMonth object to an array
  const outboundDataArray = [
    data.outboundCallsByMonth.january || 0,
    data.outboundCallsByMonth.february || 0,
    data.outboundCallsByMonth.march || 0,
    data.outboundCallsByMonth.april || 0,
    data.outboundCallsByMonth.may || 0,
    data.outboundCallsByMonth.june || 0,
    data.outboundCallsByMonth.july || 0,
    data.outboundCallsByMonth.august || 0,
    data.outboundCallsByMonth.september || 0,
    data.outboundCallsByMonth.october || 0,
    data.outboundCallsByMonth.november || 0,
    data.outboundCallsByMonth.december || 0
  ];

  // Convert liveCallsByMonth object to an array
  const liveDataArray = [
    data.liveCallsByMonth.january || 0,
    data.liveCallsByMonth.february || 0,
    data.liveCallsByMonth.march || 0,
    data.liveCallsByMonth.april || 0,
    data.liveCallsByMonth.may || 0,
    data.liveCallsByMonth.june || 0,
    data.liveCallsByMonth.july || 0,
    data.liveCallsByMonth.august || 0,
    data.liveCallsByMonth.september || 0,
    data.liveCallsByMonth.october || 0,
    data.liveCallsByMonth.november || 0,
    data.liveCallsByMonth.december || 0
  ];

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5">Monthly Calls</Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Inbound: {data.totalInboundCallsThisMonth} | Outbound: {data.totalOutboundCallsThisMonth} | Live: {data.totalLiveCallsThisMonth}
        </Typography>
        
        <BarChart
          xAxis={[{
            scaleType: 'band',
            data: months
          }]}
          series={[
            {
              id: 'inbound',
              label: 'Inbound Calls',
              data: inboundDataArray
            },
            {
              id: 'outbound',
              label: 'Outbound Calls',
              data: outboundDataArray
            },
            {
              id: 'live',
              label: 'Live Calls',
              data: liveDataArray
            },
          ]}
          height={300}
          margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
        />
      </CardContent>
    </Card>
  );
}

//#1CBAB7 green (inbound)
//#72CCFF blue (outbound)
//#DA00FF purple (live)