import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { PieChart } from '@mui/x-charts/PieChart';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import axiosInstance from '../../src/api/axios';
import { useSelector } from 'react-redux';

export default function InboundCallsByTimeChart() {
  const [duringHours, setDuringHours] = useState(0);
  const [afterHours, setAfterHours] = useState(0);
  const [loading, setLoading] = useState(true);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) return;
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/api/dashboard/inbound-calls-by-time');
        const data = response.data;

        setDuringHours(data.duringHours || 0);
        setAfterHours(data.afterHours || 0);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching inbound calls by time data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Card variant="outlined" sx={{ width: '100%' }}>
        <CardContent>
          <Typography>Loading...</Typography>
        </CardContent>
      </Card>
    );
  }

  const total = duringHours + afterHours;
  const pieData = [
    { label: 'During Hours', value: duringHours },
    { label: 'After Hours', value: afterHours },
  ];

  const colors = [
    'hsl(220, 20%, 65%)',
    'hsl(220, 20%, 42%)',
  ];

  return (
    <Card variant="outlined" sx={{ flexGrow: 1 }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Inbound Calls by Time This Month
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <PieChart
            colors={colors}
            series={[
              {
                data: pieData,
                innerRadius: 75,
                outerRadius: 100,
                paddingAngle: 0,
              },
            ]}
            width={260}
            height={260}
            slotProps={{ legend: { hidden: true } }}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: colors[0], borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              During Hours
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {total ? ((duringHours / total) * 100).toFixed(1) : 0}%
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 12, height: 12, backgroundColor: colors[1], borderRadius: '50%', mr: 1 }} />
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              After Hours
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {total ? ((afterHours / total) * 100).toFixed(1) : 0}%
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
