//Line chart in dashboard showing line representation for inbound, outbound and live-calls per month
import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';
import { useState, useEffect } from 'react';
import axiosInstance from '../../src/api/axios';
import { useSelector } from 'react-redux';




function AreaGradient({ color, id }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

AreaGradient.propTypes = {
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

function getDaysInMonth(month, year) {
  const date = new Date(year, month, 0);
  const monthName = date.toLocaleDateString('en-US', {
    month: 'short',
  });
  const daysInMonth = date.getDate();
  const days = [];
  let i = 1;
  while (days.length < daysInMonth) {
    days.push(`${monthName} ${i}`);
    i += 1;
  }
  return days;
}

export default function SessionsChart() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const theme = useTheme();
  const [callData, setCallData] = useState({
    inbound: [],
    outbound: [],
    live: [],
    days: []
  });
  const [totalCalls, setTotalCalls] = useState(0);
  const [trend, setTrend] = useState('neutral');

  useEffect(() => {
    if (!isLoggedIn) return;
    const fetchCallData = async () => {
      try {

        const response = await axiosInstance.get('/api/dashboard/call-stats/last-30-days');
        // console.log('Full API response:', response.data);
        const data = response.data;

        setCallData(data);
        
        const total = data.inbound.reduce((sum, value) => sum + value, 0) 
                    + data.outbound.reduce((sum, value) => sum + value, 0)
                    + data.live.reduce((sum, value) => sum + value, 0);
        setTotalCalls(total);

        setTrend(total > 1000 ? 'success' : 'neutral'); // Adjust this logic as needed

        // console.log('Received call data days:', callData.days);

      } catch (error) {
        console.error('Error fetching call data:', error);
      }
    };

    fetchCallData();
  }, []);

  // useEffect(() => {
  //   console.log('callData updated:', callData);
  // }, [callData]);

  const colorPalette = [
    '#1CBAB7', // Green for inbound
    '#72CCFF', // Blue for outbound
    '#DA00FF'  // Purple for live
  ];

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Calls (30 Days)
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              {totalCalls.toLocaleString()}
            </Typography>
            {/* <Chip size="small" color={trend} label={trend === 'success' ? '+10%' : '0%'} /> */}
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Call statistics for the last 30 days
          </Typography>
        </Stack>
        {callData.inbound.length > 0 || callData.outbound.length > 0 || callData.live.length > 0 ? (
  <LineChart
    colors={colorPalette}
    xAxis={[
      {
        scaleType: 'point',
        data: callData.days,
        // tickInterval: (index, i) => (i + 1) % 5 === 0,
      },
    ]}
    yAxis={[{
      scaleType: 'linear',
      // Optional: You can add label formatting, min/max values, etc.
      // label: 'Calls',
      // min: 0
    }]}
    series={[
      {
        id: 'inbound',
        label: 'Inbound',
        showMark: true,
        curve: 'linear',
        stack: 'total',
        area: true,
        stackOrder: 'ascending',
        data: callData.inbound,
      },
      {
        id: 'outbound',
        label: 'Outbound',
        showMark: false,
        curve: 'linear',
        stack: 'total',
        area: true,
        stackOrder: 'ascending',
        data: callData.outbound,
      },
      {
        id: 'live',
        label: 'Live',
        showMark: false,
        curve: 'linear',
        stack: 'total',
        stackOrder: 'ascending',
        data: callData.live,
        area: true,
      },
    ]}
    height={250}
    margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
    grid={{ horizontal: true }}
    sx={{
      '& .MuiAreaElement-series-live': {
        fill: "url('#live')",
      },
      '& .MuiAreaElement-series-outbound': {
        fill: "url('#outbound')",
      },
      '& .MuiAreaElement-series-inbound': {
        fill: "url('#inbound')",
      },
    }}
    slotProps={{
      legend: {
        hidden: true,
      },
    }}
  >
    <AreaGradient color={theme.palette.primary.dark} id="live" />
    <AreaGradient color={theme.palette.primary.main} id="outbound" />
    <AreaGradient color={theme.palette.primary.light} id="inbound" />
  </LineChart>
) : (
  <Typography variant="body2">Loading data or no data available...</Typography>
)}
      </CardContent>
    </Card>
  );
}