import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from './AppNavbar';
import Header from './Header';
import MainGrid from './MainGrid';
import SideMenu from './SideMenu';
import SideMenuMobile from './SideMenuMobile';
import AppTheme from '../shared-theme/AppTheme';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from './theme/customizations';
import { SiTruenas } from 'react-icons/si';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Dashboard(props) {
  const navigate = useNavigate();
  const { user, isLoggedIn, loading } = useSelector((state) => state.auth);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('monthlyMetrics');
  

  useEffect(() => {
    if (!isLoggedIn && !loading) {
      navigate('/login');
    }
  }, [isLoggedIn, loading, navigate]);

  if (loading || !user) {
    return <p>Loading user data...</p>;
  }

  // if (user) {
  //   console.log(user.firstName, user.lastName, user.email);
  // }
  const handleMenuClick = () => {
    setIsMobileMenuOpen((prev) => !prev);
    //console.log('Menu click toggled to:', !isMobileMenuOpen);
  };

  // Function to explicitly open or close the drawer
  const toggleDrawer = (open) => {
    setIsMobileMenuOpen(open);
    //console.log(`Drawer set to ${open ? 'open' : 'closed'}`);
  };

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
      <SideMenu user={user} setActiveSection={setActiveSection} />
      <SideMenuMobile
          user={user}
          open={isMobileMenuOpen}
          toggleDrawer={toggleDrawer}
          setActiveSection={setActiveSection}
        />

        {/* Navbar */}
        <AppNavbar onMenuClick={handleMenuClick} />
      
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header activeSection={activeSection} />
            <MainGrid section={activeSection} />
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
