import { createAction } from '@reduxjs/toolkit';

// Login and Registration Actions
export const authRequested = createAction('AUTH_REQUESTED');  // Used for both login and registration
export const authSuccess = createAction('AUTH_SUCCESS');      // Stores user data and token
export const authFailed = createAction('AUTH_FAILED');        // Handles errors

// Logout Actions
export const logoutRequested = createAction('LOGOUT_REQUESTED');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');
export const tokenExpired = createAction('TOKEN_EXPIRED');
